(function () {
    angular.module('informaApp')
        .component('trendsViewProfile', {
            templateUrl: 'components/trends-view-profile/template.html',
            controller: TrendsViewProfileComponent,
            bindings: {
                source: '<',
                hiddenDrugs: '<',
                chartFilter: '<',
                onEmpty: '<',
                onShow: '<',
                getSearchData: '<',
                onChartFilterChanges: '<'
            }
        });

    const title = 'Trends View';

    class TrendsViewProfileState {
        constructor() {
            this.modalElement = null;
            this.chartFilter = null;
            this.profileData = null;

            this.isLoaded = false;
        }

        subscribeOnLoad(callback) {
            this.onLoad = callback;
        }

        subscribeOnChanges(callback) {
            this.onChanges = callback;
        }

        setModalElement(modalElement) {
            const oldValue = this.modalElement;
            this.modalElement = modalElement;

            this._callOnChanges({
                modalElement: {
                    oldValue,
                    newValue: modalElement
                }
            });
            this._callOnLoad();
        }

        setChartFilter(chartFilter) {
            const oldValue = this.chartFilter;
            this.chartFilter = chartFilter;

            this._callOnChanges({
                chartFilter: {
                    oldValue,
                    newValue: chartFilter
                }
            });
            this._callOnLoad();
        }

        setProfileData(profileData) {
            const oldValue = this.profileData;
            this.profileData = profileData;

            this._callOnChanges({
                profileData: {
                    oldValue,
                    newValue: profileData
                }
            });
            this._callOnLoad();
        }

        _callOnLoad() {
            if (!this.isLoaded && this.modalElement && this.chartFilter && this.profileData) {
                this.onLoad && this.onLoad();
                this.isLoaded = true;
            }
        }

        _callOnChanges(changes) {
            this.isLoaded && this.onChanges && this.onChanges(changes);
        }
    }

    function TrendsViewProfileComponent(ModalHelper, TrendsViewMapper, ExportTrendsViewService) {
        this.trendsViewProfileState = new TrendsViewProfileState();

        this.profileData = {
            data: {name: title}
        };

        this.chartOptions = {};

        this.changeXAxis = false;
        this.showNumbers = false;
        this.showSummaryTable = true;

        this.compareData = (tabLeft, tabRight) => tabLeft.data.name === tabRight.data.name;

        this.onProfileEmpty = () => {
            emitOnEmpty.call(this);
        };

        this.onProfileShow = (tab) => {
            this.trendsViewProfileState.setProfileData(tab);
        };

        this.onFilterChanges = (filter) => {
            this.trendsViewProfileState.setChartFilter(filter);
        };

        this.onLoadingModalInit = (modalElement) => {
            this.trendsViewProfileState.setModalElement(modalElement);
        };

        this.trendsViewProfileState.subscribeOnLoad(() => {
            if (this.chartFilter) {
                this.trendsViewProfileState.chartFilter = this.chartFilter || this.trendsViewProfileState.chartFilter;
                this.changeXAxis = this.chartFilter.inverted;
            }

            const profileData = this.trendsViewProfileState.profileData;

            if (!profileData.data.chartData && !this.source) {
                return emitOnEmpty.call(this);
            }

            if (profileData.data.chartData && !this.source) {
                this.source = profileData.data.source;
                return applyTab.call(this, profileData);
            }

            emitOnFilterChanges.call(this);
            loadChartData.call(this, ModalHelper, TrendsViewMapper);
        });

        this.onChartUpdates = (chart) => {
            this.chartColors = chart.colors;
        };

        this.trendsViewProfileState.subscribeOnChanges((changes) => {
            if (changes.chartFilter) {
                onChartFilterChanged.call(this, changes, ModalHelper, TrendsViewMapper);
            }

            if (changes.profileData) {
                onCheckboxesValuesChange.call(this, changes);
            }
        });

        this.onCheckboxValueChanges = () => {
            updateProfileData.call(this, {checkboxes: getCheckboxesValues.call(this)});
        };

        this.exportCharts = () => {
            const exportChart = this.changeXAxis
                ? ExportTrendsViewService.exportInvertedTrendsView
                : ExportTrendsViewService.exportTrendsView;

            const searchData = this.getSearchData();

            this.isExporting = true;

            exportChart(searchData, this.chartData, this.chartColors, this.showNumbers)
                .finally(() => this.isExporting = false);
        };
    }

    function onCheckboxesValuesChange(changes) {
        const {showSummaryTable, changeXAxis, showNumbers} = this.trendsViewProfileState.profileData.data.checkboxes;

        if (isChanged(changes.profileData, x => x.data.checkboxes ? x.data.checkboxes.changeXAxis : null)) {
            updateChartOptions.call(this);
            emitOnFilterChanges.call(this);
        }

        this.showSummaryTable = showSummaryTable;
        this.showNumbers = showNumbers;
        this.changeXAxis = changeXAxis;
    }

    function onChartFilterChanged(changes, ModalHelper, TrendsViewMapper) {
        if (isChanged(changes.chartFilter, x => x.yearRange)) {
            loadChartData.call(this, ModalHelper, TrendsViewMapper);
        }

        if (isChanged(changes.chartFilter, x => x.metric)) {
            updateProfileData.call(this, {chartFilter: changes.chartFilter.newValue});
            applyTab.call(this, this.profileData);
        }

        emitOnFilterChanges.call(this);
    }

    function isChanged(changedObject, getProperty) {
        return getProperty(changedObject.oldValue) !== getProperty(changedObject.newValue);
    }

    function loadChartData(ModalHelper, TrendsViewMapper) {
        showLoadingModal.call(this, ModalHelper);

        return loadTrendsViewData
            .call(this, TrendsViewMapper)
            .then((chartData) => {
                generateProfileData.call(this, chartData);
            })
            .finally(() => {
                hideLoadingModal.call(this, ModalHelper);
            });
    }

    function emitOnEmpty() {
        if (this.onEmpty) {
            this.onEmpty();
        }
    }

    function emitOnFilterChanges() {
        if (this.onChartFilterChanges) {
            this.onChartFilterChanges({
                metric: this.trendsViewProfileState.chartFilter.metric,
                yearRange: this.trendsViewProfileState.chartFilter.yearRange,
                inverted: this.changeXAxis
            });
        }
    }

    function updateProfileData(newData) {
        this.profileData = {
            data: _.merge({}, this.trendsViewProfileState.profileData.data, newData),
            replace: true
        };
    }

    function showLoadingModal(ModalHelper) {
        if (this.trendsViewProfileState.modalElement) {
            ModalHelper.showLoadingModalByElement(this.trendsViewProfileState.modalElement);
        } else {
            this.shouldShowLoadingModalWhenInit = true;
        }
    }

    function hideLoadingModal(ModalHelper) {
        ModalHelper.hideModalByElement(this.trendsViewProfileState.modalElement);
    }

    function loadTrendsViewData(TrendsViewMapper) {
        return TrendsViewMapper.map(this.source, this.trendsViewProfileState.chartFilter.yearRange, this.hiddenDrugs);
    }

    function generateProfileData(chartData) {
        this.profileData = {
            data: {
                name: title,
                chartData,
                source: this.source,
                chartFilter: this.trendsViewProfileState.chartFilter,
                hiddenDrugs: this.hiddenDrugs,
                checkboxes: getCheckboxesValues.call(this)
            },
            replace: true
        };

        applyTab.call(this, this.profileData);
    }

    function getCheckboxesValues() {
        return {
            showSummaryTable: this.showSummaryTable,
            changeXAxis: this.changeXAxis,
            showNumbers: this.showNumbers
        };
    }

    function applyTab(tab) {
        this.chartData = tab.data.chartData;
        this.hiddenDrugs = tab.data.hiddenDrugs;
        this.filter = tab.data.chartFilter;

        this.changeXAxis = tab.data.checkboxes.changeXAxis;
        this.showSummaryTable = tab.data.checkboxes.showSummaryTable;
        this.showNumbers = tab.data.checkboxes.showNumbers;

        updateChartOptions.call(this);
    }

    function updateChartOptions() {
        this.chartOptions = _.merge({}, this.chartOptions, {inverted: this.changeXAxis});
    }
})();
